// Entry point for the build script in your package.json
require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
//import "./channels"
import imagesLoaded from 'imagesloaded'
import jquery from "jquery"
window.jQuery = jquery
window.$ = jquery
import Packery from 'packery'

$(document).on('turbo:load', function() {
	var container = document.querySelector('.news-container');
	var pckry;
  if(container) {
  	imagesLoaded( container, function() {
  	  pckry = new Packery( container, {
  	    // options
  	    columnWidth: 300,
  	    itemSelector: '.news-story',
  	    gutter: 5
  	  });
  	});
  }
});

document.addEventListener("turbo:load", () => {
  $('.entry-nav').click(function()
  {
    $('.entry-nav').removeClass('active');
    $(this).removeClass('new');
    $(this).addClass('active');
  });
});

import "@hotwired/turbo-rails"
